import { useAPI } from "@/composables/useAPI";

export function usePaginationAPI() {
    const { api } = useAPI()

    const getPaginatedList = async (routePath: string, offset: number, perPage: number, filter?: string): Promise<{ list: any[]; maxCount: number }> => {
        const response = await api.get(routePath,
            {
                params: {
                    offset: offset,
                    limit: perPage,
                    searchKey: filter
                }
            })

        if (!response) return { list: [], maxCount: 0 }
        return { list: response.data.list as any[], maxCount: response.data.maxCount }
    }

    return {
        getPaginatedList
    }
}