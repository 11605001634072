import { usePaginationAPI } from "@/api-connectors/usePaginationAPI";
import { Ref } from "@vue/composition-api";

export function useTable() {
    const { getPaginatedList } = usePaginationAPI()

    const pageOptions = [10, 20, 50]
    const updateTable = async (updateTableObject: {
        routePath: string,
        targetList: Array<any>,
        targetMaxCount: Ref<number>,
        offset: number,
        perPage: number,
        filter?: string,
        convertFunction?: Function
    }): Promise<void> => {
        const { routePath, targetList, targetMaxCount, offset, perPage, filter, convertFunction } = updateTableObject

        const { list, maxCount } = await getPaginatedList(routePath, offset, perPage, filter);

        if (!list || maxCount === undefined) return;

        if (convertFunction) {
            const convertedList: any[] = convertFunction(list)
            if (convertedList) fillList(targetList, convertedList)
        } else fillList(targetList, list)

        targetMaxCount.value = maxCount
    }

    const fillList = (targetList: any[], sourceList: any[]) => {
        targetList.splice(0)
        for (const item of sourceList) {
            targetList.push(item);
        }
    }

    return {
        pageOptions,
        updateTable,
        fillList
    }
}